$(document).ready(function () {

	if( $( window ).width() < 769 ){

		$( ".sticky-search" ).click(function(e) {
			
			e.preventDefault();		
			$('.searchbar-wrapper').toggle('fast');
			
		});

		if($('#product').length){
			
			$('.wp-product-right').detach().insertAfter(".wp-product-left .product-left");

		}

	}

	if( $('#product').length && $( window ).width() > 768 ){
		var observer = new IntersectionObserver(function(entries) {
			// no intersection with screen
			if(entries[0].intersectionRatio === 0)
				document.querySelector("#product .wp-product .wp-product-right .product-right").classList.add("is-pinned");
			// fully intersects with screen
			else if(entries[0].intersectionRatio === 1)
				document.querySelector("#product .wp-product .wp-product-right .product-right").classList.remove("is-pinned");
		}, { threshold: [0,1] });
		
		observer.observe(document.querySelector("#product .wp-product .wp-product-right .wp-product-right-sticky-js"));

	}
	if( $('#product').length){
		setTimeout(function() {
			console.log('ici');
			var text = $('.alma-payment-plans-payment-info').text();
			var ret = text.replace('(sans frais)','');
			$('.alma-payment-plans-payment-info').html(ret);
			console.log(ret);
		}, 2000);

	}
	

// 	var result = $('.miniature-countdown-wrapper .miniature-countdown-box .countdown-box span.countdown-time time').text().split(':');
// alert( result[2] );
	

});
